import * as React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

export default function MainAppBar() {
    const navigate = useNavigate();

    React.useEffect(() => {
        if (localStorage.getItem("secretPassword") !== "qV6)f7+7sq_9") {
            navigate("signIn")
        }
    }, []);
    return (
        <React.Fragment >
             <Header />
            <div style={{
                backgroundColor: 'white',
                minHeight: '100vh'

            }}>
                

                <Outlet />
            </div>
           
          
            <Footer />
        </React.Fragment>
    );
}
