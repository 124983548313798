import React, { useEffect, useRef, useState } from 'react';
import {
    MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents, Polygon
} from "react-leaflet";
import MarkerClusterGroup from 'react-leaflet-cluster'
import L from "leaflet";
import {
    FormLabel, Checkbox, FormGroup, FormControlLabel, Box, CardActions, IconButton, List, Divider, ListItem, Drawer, ListItemButton, ListItemIcon, ListItemText, Menu, Grid, Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import FilterDialog from './FilterDialog';
import roomIconMapaAmarelo from '../components/Imagens/roomIconMapaAmarelo.png';
import roomIconMapaAzul from '../components/Imagens/roomIconMapaAzul.png';
import roomIconMapaLaranja from '../components/Imagens/roomIconMapaLaranja.png';
import roomIconMapaVerde from '../components/Imagens/roomIconMapaVerde.png';
import roomIconMapaVermelho from '../components/Imagens/roomIconMapaVermelho.png';

import { useFonteDados } from '../services/useFonteDados';
import { coordenadasListPiaui } from './Util';
import './Header.css'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import SearchIcon from '@mui/icons-material/Search'

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

let CustomIcon = L.icon({
    iconUrl: roomIconMapaVermelho,
    // iconRetinaUrl: require('../../public/roomIconMapa'),
    iconAnchor: [14, 42], //  [26, 57],
    popupAnchor: [-1.5, -42] //  [-6, -54]
});


const purpleOptions = { color: '#1B3058', fill: false };
const constZoom = 7
const maxZoom = 13
let coord = [-6.5865973, -42.8404006]; // [-5.08921, -42.8016];
let mudacodernada = false;

function Mapa() {
    const inputEl = useRef(null);

    const [zoom, setZoom] = useState(constZoom)
    const [open, setOpen] = useState(false);
    const [dialogInfo, setDialogInfo] = useState();
    const [fonteDado, setFonteDado] = useState(null);
    const [centro, setCentro] = useState([coord[0], coord[1]]);
    const [polygon, setPolygon] = useState(false);
    const [agrupar, setAgrupar] = useState(true)
    const [lote1, setLote1] = useState(false)
    const [lote2, setLote2] = useState(false)
    const [lote3, setLote3] = useState(false)
    const [lote4, setLote4] = useState(false)
    const [lote5, setLote5] = useState(false)
    const [menuLateral, setMenuLateral] = useState(false)

    let [dadoCSV, cidades] = useFonteDados();

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });



    useEffect(() => {
        // const fonte = localStorage.getItem("fonteDado");
        // if (!fonte) buscaSetting();
        // else setFonteDado(JSON.parse(fonte));
        setFonteDado(dadoCSV);
        let dadosPoly = coordenadasListPiaui();
        if (dadosPoly) setPolygon(dadosPoly);
    }, [dadoCSV]);

    useEffect(() => {
        if (!lote1 && !lote2 && !lote3 && !lote4 && !lote5) {
            setFonteDado(dadoCSV);
            setAgrupar(true);
            console.log("eseção")
            return;
        }
        let arr = []
        for (let x in dadoCSV) {
            const item = dadoCSV[x]
            if (lote1 && item.LOTE === '1') arr.push(item)
            if (lote2 && item.LOTE === '2') arr.push(item)
            if (lote3 && item.LOTE === '3') arr.push(item)
            if (lote4 && item.LOTE === '4') arr.push(item)
            if (lote5 && item.LOTE === '5') arr.push(item)
        }
        setFonteDado(arr);
        if (lote1 || lote2 || lote3 || lote4 || lote5) {
            setAgrupar(false)
        }
        if (arr.length === 0) {
            setAgrupar(true);
            setFonteDado(dadoCSV);
        }
    }, [lote1, lote2, lote3, lote4, lote5]);

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const onClickAgrupar = () => {
        setAgrupar(!agrupar);
        setLote1(false);
        setLote2(false);
        setLote3(false);
        setLote4(false);
        setLote5(false);
    }

    const list = (anchor) => (
        <Box
            // sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            sx={{ '& button': { m: 1 } }}
            role="presentation"
        //   onClick={toggleDrawer(anchor, false)}
        //   onKeyDown={toggleDrawer(anchor, false)}
        >



            <FormGroup style={{ marginLeft: '10px' }} >
                {/* <IconButton aria-label="filter" onClick={() => setOpen(true)} color="secondary">
                    <SearchIcon />
                </IconButton> */}
                <FormLabel style={{ marginLeft: '17px', color: 'white' }} component="legend">Tipo de Lote</FormLabel>
                <Button variant="contained" onClick={() => setLote1(!lote1)} sx={{
                    backgroundColor: lote1 ? '#FFD326' : '#F4F6F8',
                    color: 'black',
                    '&:hover': {
                        backgroundColor: '#FFD326', // Mantenha a cor amarela ao passar o mouse
                    }
                }} >Lote 1</Button>
                <Button variant="contained" onClick={() => setLote2(!lote2)} sx={{
                    backgroundColor: lote2 ? '#3232CD' : '#F4F6F8',
                    color: 'black',
                    '&:hover': {
                        backgroundColor: '#3232CD', // Mantenha a cor amarela ao passar o mouse
                    }
                }} >Lote 2</Button>
                <Button variant="contained" onClick={() => setLote3(!lote3)} sx={{
                    backgroundColor: lote3 ? '#CB8427' : '#F4F6F8',
                    color: 'black',
                    '&:hover': {
                        backgroundColor: '#CB8427', // Mantenha a cor amarela ao passar o mouse
                    }

                }} >Lote 3</Button>
                <Button variant="contained" onClick={() => setLote4(!lote4)} sx={{
                    backgroundColor: lote4 ? '#2AAD27' : '#F4F6F8',
                    color: 'black',
                    '&:hover': {
                        backgroundColor: '#2AAD27', // Mantenha a cor amarela ao passar o mouse
                    }
                }} >Lote 4</Button>
                <Button variant="contained" onClick={() => setLote5(!lote5)} sx={{
                    backgroundColor: lote5 ? '#CD3232' : '#F4F6F8',
                    color: 'black',
                    '&:hover': {
                        backgroundColor: '#CD3232', // Mantenha a cor amarela ao passar o mouse
                    }
                }} >Lote 5</Button>

                <Divider />
                <Button variant="contained" onClick={onClickAgrupar} sx={{ 
                    backgroundColor: agrupar ? '#1B3058' : '#F4F6F8', 
                    color: agrupar ? 'white' : 'black' ,
                    '&:hover': {
                        backgroundColor: '#1B3058', // Mantenha a cor amarela ao passar o mouse
                    }
                    }} >Agrupar</Button>

            </FormGroup>

        </Box>
    );

    const aplicaFiltro = (e) => {
        let arr = [];
        if (e && e.cidade && e.cidade.length >= 1) {
            for (let x in dadoCSV) {
                const cidade = dadoCSV[x]['NOME CIDADE'];
                if (e.cidade.find(e => e === cidade)) {
                    arr.push(dadoCSV[x])
                    if(e.cidade.length == 1){
                        setCentro([dadoCSV[x].LAT, dadoCSV[x].LNG])
                        mudacodernada = true;
                    }
                }
            }
            setFonteDado(arr);
        }
        if (!arr || arr.length === 0) {
            setFonteDado(dadoCSV);
        }
    }
    const RecenterAutomatically = ({ lat, lng }) => {
        const map = useMap();
        useEffect(() => {
            if(mudacodernada) { 
                map.setView([lat, lng]);
                mudacodernada=false;
            }
            return //não esta funcionando com o componente group
            if (zoom > 10) map.setView([lat, lng]);
        }, [lat, lng]);
        return null;
    }
    function getLocal(disp) {
        return disp['SECRETARIA'] + " - " + disp['PAG'];
    }
    function getDistanceFromLatLonInMetros(lat, lng) {
        if (!lat || !lng) return '';
        var deg2rad = function (deg) { return deg * (Math.PI / 180); },
            R = 6371,
            dLat = deg2rad(coord[0] - lat),
            dLng = deg2rad(coord[1] - lng),
            a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
                + Math.cos(deg2rad(lat))
                * Math.cos(deg2rad(lat))
                * Math.sin(dLng / 2) * Math.sin(dLng / 2),
            c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return ((R * c * 1000).toFixed());
    }

    function LocationMarker() {
        const [position, setPosition] = useState(null)
        const map = useMapEvents({
            click(e) {
                console.log("...", e.latlng)
                // map.locate()
            },
            zoomend(e) {
                console.log(">>zoom<<", map.getZoom())
                setZoom(map.getZoom());
            },
            dragend(e) {
                // console.log(">>dragend<<", e.target.getCenter())
                const latlng = e.target.getCenter();
                // console.log( latlng );
                const d = getDistanceFromLatLonInMetros(latlng.lat+0.5, latlng.lng) / 1000;
                console.log(e.distance, d);
                if (d > 500) { //e.distance > 2000){
                    const latlng = {
                        lat: coord[0],
                        lng: coord[1]
                    }
                    map.flyTo(latlng, map.getZoom())
                }
            },
            locationfound(e) {
                setPosition(e.latlng)
                map.flyTo(e.latlng, map.getZoom())
            },
        })

        return position === null ? null : (
            <Marker position={position}>
                <Popup>You are here</Popup>
            </Marker>
        )
    }

    function buscarIcone(d) {
        let icone = roomIconMapaVermelho;
        if (d == 1) {
            icone = roomIconMapaAmarelo;
        } else if (d == 2) {
            icone = roomIconMapaAzul;
        } else if (d == 3) {
            icone = roomIconMapaLaranja;
        } else if (d == 4) {
            icone = roomIconMapaVerde;
        } else if (d == 5) {
            icone = roomIconMapaVermelho;
        }

        let resp = L.icon({
            iconUrl: icone,
            // iconRetinaUrl: require('../../public/roomIconMapa'),
            iconAnchor: [14, 42],
            popupAnchor: [-1.5, -42] //  [-6, -54]
        });
        // console.log(d)
        return resp;
    }

    return (
        <React.Fragment>
            <Grid container >
                {menuLateral && <Grid item xs={1} justify="center" alignItems="center" sx={{ backgroundColor: '#1B3058' }}>
                    {list("left")}
                </Grid>}
                <Grid item xs={menuLateral ? 11 : 12} sx={{ minHeight: '100vh' }} >

                    {fonteDado &&
                        <MapContainer
                            ref={inputEl} style={{
                                // position: 'absolute',
                                height: '100%',
                                width: '100%'
                            }}

                            center={[coord[0], coord[1]]} zoom={constZoom} scrollWheelZoom={true} minZoom={6}>
                            {/* <Markers /> */}
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <div className='iconWrapper'>
                                <div className="topcornerFilter">
                                    <IconButton aria-label="filter" onClick={() => setMenuLateral(!menuLateral)} sx={{ height: 1}}>
                                        {menuLateral ? <KeyboardDoubleArrowLeftIcon sx={{ color: '#1B3058' }} /> : <KeyboardDoubleArrowRightIcon sx={{ color: '#1B3058' }} />}
                                    </IconButton>
                                    <IconButton aria-label="filter" onClick={() => setOpen(true)} >
                                        <SearchIcon sx={{ color: '#1B3058' }} />
                                    </IconButton>
                                </div>
                            </div>

                            {polygon && (polygon.length > 0) && <Polygon pathOptions={purpleOptions} positions={polygon} />}

                            {(!agrupar || zoom > maxZoom) && fonteDado && fonteDado.map((disp, index) => (
                                <Marker key={index} position={[disp.LAT, disp.LNG]} icon={buscarIcone(disp.LOTE)}>
                                    <Popup>
                                        <b>Cidade:</b> {disp['NOME CIDADE']} <br />
                                        <b>Bairro:</b> {disp.BAIRRO} <br />
                                        <b>Local: </b>{getLocal(disp)}  <br />
                                        <b>Link: </b>{disp['PLANO TOTAL'] + " Mbps"}<br />

                                        <CardActions sx={{
                                            alignSelf: "stretch",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "flex-start",
                                            // 👇 Edit padding to further adjust position
                                            p: 0,
                                        }} >

                                        </CardActions>
                                    </Popup>
                                </Marker>
                            ))}

                            <MarkerClusterGroup
                                chunkedLoading
                            >
                                {zoom <= maxZoom && agrupar && fonteDado && fonteDado.map((disp, index) => (
                                    // <Marker
                                    //     key={index}
                                    //     position={[disp.LAT, disp.LNG]}
                                    //     title={disp['NOME CIDADE']}
                                    //     icon={CustomIcon}
                                    // ></Marker>
                                    <Marker key={index} position={[disp.LAT, disp.LNG]} icon={buscarIcone(disp.LOTE)}>
                                        <Popup>
                                            <b>Cidade:</b> {disp['NOME CIDADE']} <br />
                                            <b>Bairro:</b> {disp.BAIRRO} <br />
                                            <b>Local: </b>{getLocal(disp)}  <br />
                                            <b>Link: </b>{disp['PLANO TOTAL'] + " Mbps"}<br />

                                            <CardActions sx={{
                                                alignSelf: "stretch",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "flex-start",
                                                // 👇 Edit padding to further adjust position
                                                p: 0,
                                            }} >

                                            </CardActions>
                                        </Popup>
                                    </Marker>
                                ))}
                            </MarkerClusterGroup>
                            <RecenterAutomatically lat={centro[0]} lng={centro[1]} />
                            <LocationMarker />

                        </MapContainer>}

                </Grid>


            </Grid>
            {fonteDado && <FilterDialog action={aplicaFiltro} fonteDado={fonteDado} setOpen={setOpen} open={open} />}

            {/* <div className="topcornerFilterMore" style={{ marginTop: "30px" }}>
                <IconButton aria-label="filter" onClick={toggleDrawer("left", true)} color="secondary">
                    <ModeIcon />
                </IconButton>
            </div>
            <Drawer
                anchor={"left"}
                open={state["left"]}
                // onClose={toggleDrawer("left", false)}
                variant="persistent"
                sx={{
                    // width: drawerWidth,
                    height: '12%',
                    flexShrink: 0,
                    // [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <DrawerHeader>
                    <IconButton onClick={toggleDrawer("left", false)}>
                        {<ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />

                {list("left")}
            </Drawer> */}

        </React.Fragment>
    )
}

export default Mapa;