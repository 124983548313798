import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap, Polygon } from "react-leaflet";
import L from "leaflet";
import {
    IconButton
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search'

import FilterDialog from './FilterDialog';
import roomIconMapa from '../components/Imagens/roomIconMapaVermelho.png';
import { useFonteDados } from '../services/useFonteDados';
import { coordenadasListPiaui } from './Util';
import './Header.css'

let CustomIcon = L.icon({
    iconUrl: roomIconMapa,
    // iconRetinaUrl: require('../../public/roomIconMapa'),
    iconAnchor: [14, 42], //  [26, 57],
    popupAnchor: [-1.5, -42] //  [-6, -54]

});
// let referencia = { lat: -22.8910135, lng: -47.1258095 }
let coord = [-6.5865973, -42.8404006]; // [-5.08921, -42.8016];
const purpleOptions = { color: '#1B3058', fill: false };
// let fonteDado=[];
function MapaConsolidado() {
    const inputEl = useRef(null);

    const [open, setOpen] = useState(false);
    const [dialogInfo, setDialogInfo] = useState();
    const [fonteDado, setFonteDado] = useState(null);
    const [centro, setCentro] = useState([coord[0], coord[1]]);
    const [polygon, setPolygon] = useState(false);
    let [dadoCSV, cidades] = useFonteDados();


    useEffect(() => {
        buscaConsolidado();
        let dadosPoly = coordenadasListPiaui();
        if(dadosPoly) setPolygon(dadosPoly);
    }, [dadoCSV]);

    function buscaConsolidado() {
        if( !cidades ) return;
        let arr = []
        for (const [key, value] of cidades) {
            arr.push( value )
          }
          setFonteDado(arr)
    }
    
    const aplicaFiltro=(e)=>{
        let arr = [];
        console.log(e)
        if( e && e.cidade && e.cidade.length >= 1){
            for(let x in dadoCSV){
                const cidade = dadoCSV[x]['NOME CIDADE'];
                if( e.cidade.includes(cidade) ){
                    arr.push(dadoCSV[x])
                    setCentro([dadoCSV[x].LAT, dadoCSV[x].LNG])
                }
            }
            setFonteDado(arr);
        }
        if( !arr || arr.length ===0 ){
            buscaConsolidado();
        }    
    }
    const RecenterAutomatically = ({lat,lng}) => {
        const map = useMap();
         useEffect(() => {
           map.setView([lat, lng]);
         }, [lat, lng]);
         return null;
       }
    function montaLinks(disp){
        var html ="";
        let mapa = new Map();
        let total =0;
        for(let x in disp.planos){
            const plano = parseInt( disp.planos[x] );
            let value = mapa.get(plano);
            if( value) {
                mapa.set(plano, value+1);
            }else{
                mapa.set(plano, 1)
            }
            // if( disp[x].trim().length === 0 ) continue;
            total++;
        }
        const sortedAsc = new Map([...mapa].sort( (a, b) => a[0] - b[0] ));

        for (const [key, value] of sortedAsc) {
            html += "<b>"+key+" Mbps</b>: "+value+"<br/>"
        }
        html += "<b>Total</b>: "+total+"<br/>"
        
        return (
            <div dangerouslySetInnerHTML={{__html: html}}></div>
        )
    }
    return (
        <React.Fragment>
            {fonteDado &&
                <MapContainer
                    ref={inputEl} style={{
                        position: 'absolute',
                        height: '100%',
                        width: '100%'
                    }}
                    center={centro} zoom={7} scrollWheelZoom={true}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                            <div className='iconWrapper'>
                                <div className="topcornerFilter">
                                    
                                    <IconButton aria-label="filter" onClick={() => setOpen(true)} >
                                        <SearchIcon sx={{ color: '#1B3058' }} style={{marginTop:"10"}} />
                                    </IconButton>
                                </div>
                            </div>

                    { polygon && (polygon.length > 0) && <Polygon pathOptions={purpleOptions} positions={polygon} />}
                    {fonteDado && fonteDado.map((disp, index) => (
                        <Marker key={index} position={[disp.LAT, disp.LNG]} icon={CustomIcon}>
                            <Popup>
                                <b>Cidade:</b> {disp['NOME CIDADE']} <br />
                                {montaLinks(disp)}
                            </Popup>
                        </Marker>
                    ))}
                    <RecenterAutomatically lat={centro[0]} lng={centro[1]} />
                </MapContainer>}
            
            {fonteDado && <FilterDialog action={aplicaFiltro} fonteDado={fonteDado} setOpen={setOpen} open={open}/> }
        </React.Fragment>
    )
}

export default MapaConsolidado;