import * as React from 'react';
import Service from './Service';
const useFonteDados = () => {

    const [data, setData] = React.useState();
    const [cidades, setCidades] = React.useState();

    React.useEffect(() => {
        // const fonte = localStorage.getItem("fonteDado");
        // if (!fonte) buscaSetting();
        // else{
        //     const arr = JSON.parse(fonte);
        //     setData(arr);
        //     montaCidade(arr)
        // } 

        buscaSetting();

    }, [])

    function montaCidade(arr){
        let mapa = new Map();
        let i = 0;
        for(let x in arr){
            const j = arr[x];
            const plano = j['PLANO TOTAL']
            const cidade = j['NOME CIDADE'];
            let lat = j.LAT;
            let lng = j.LNG;

            let obj = mapa.get(cidade);
            if( obj){ 
                obj.planos.push(plano)
            }else{
                obj = {
                    'NOME CIDADE': cidade,
                    planos: [plano],
                    LAT: lat,
                    LNG: lng
                }
                mapa.set(cidade, obj)
            }
            // if( i++ > 50) break;
        }
        const sortedAsc = new Map([...mapa].sort() );

        setCidades( sortedAsc );
    }

    function buscaSetting() {
        Service.GET('/fonteDado.csv')
            .then(r => {
                // console.log(r)
                let arr = r.split("\n");
                let cab;
                let arrFont = [];

                for (let i = 0; i < arr.length; i++) {
                    let linha = arr[i];
                    const a = linha.split("|")
                    if (i === 0) {
                        cab = a;
                    } else {
                        let j = {};
                        for (let x in cab) {
                            if (cab[x] === 'LAT' || cab[x] === 'LNG') {
                                if (typeof (a[x]) != 'undefined' && a[x] != null) {
                                    j[cab[x]] = parseFloat(a[x].replace(",", "."));
                                } else {
                                    j[cab[x]] = null;
                                }
                            } else {
                                j[cab[x].trim() ] = a[x] ? a[x].trim():null
                            }
                        }
                        if (!j.LAT || !j.LNG) {
                            console.error("coordenada nao encontrada", j['CHAVE'])
                            continue;
                        }
                        arrFont.push({ ...j });
                    }
                }
                localStorage.setItem("fonteDado", JSON.stringify(arrFont));
                montaCidade(arrFont)
                setData(arrFont);
            });
    }
    return [data, cidades];
}

export { useFonteDados }