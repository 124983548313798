import React, { useEffect } from "react";

import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';

import './Header.css'

export default function Header() {

    useEffect(() => {
        const currentUrl = window.location.pathname;
        const navLinks = document.querySelectorAll('header a');
        navLinks.forEach((link) => {
            if (link.getAttribute('href') === currentUrl) {
                link.classList.add('active');
            }
        });
    }, []);


    return (
        <header>
            <nav className="header-menu">

                <ul>
                    <li><a href="/"></a>ETIPI - Empresa de Tecnologia da Informação do Piauí</li>

                </ul>
            </nav>
            <nav className="sub-menu">
                <img src="logoPiaui.jpg" />
            </nav>

            <nav className="main-navbar">
                <ul>
                    <li> <a href="/"> Home</a></li>
                    <li> <a href="/table"> Tabela</a></li>
                    <li> <a href="/consolidado">Consolidação</a></li>

                </ul>
            </nav>
            <div className="topcorner">
                <IconButton aria-label="settings" href="/settings">
                    <SettingsIcon style={{ color: 'white' }} />
                </IconButton>
            </div>
        </header>
    );

}
