import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { Alert, Dialog, DialogActions, Slide, DialogTitle, DialogContent, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import Service from '../services/Service';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PontoReferenciaMapa() {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {

    buscaSetting();
  }, []);
  function buscaSetting() {
    Service.GET('/api/setting')
      .then(r => {
        if( !r || !r.pontoReferencia) return;
        //setCoord([r.pontoReferencia.latitude, r.pontoReferencia.longitude])
        document.getElementById("latlong").value = r.pontoReferencia.latitude+","+r.pontoReferencia.longitude
      });
  }

  const salvar = () => {
    const coord = document.getElementById("latlong").value;
    if (coord.indexOf(",") < 0) {
      setOpen(true)
    } else {
      let arr = coord.split(",");
      const dado = {
        latitude: arr[0],
        longitude: arr[1]
      }
      Service.POST('/api/setting/salvaPontoReferencia', dado)
        .then(r => {
          // setTotal(r.size)
          // setProva(r.dado);
        });
    }
  }



  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
    >


      <IconButton sx={{ p: '10px' }} aria-label="menu">
        <MenuIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }} id="latlong"
        placeholder="Latitude,Longitude"
        inputProps={{ 'aria-label': 'latitude e longitude no mapa' }}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={salvar}>
        <SaveIcon />
      </IconButton>


      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Coordenada no mapa"}</DialogTitle>
        <DialogContent>
          <Alert severity="error">Informe a latitude separada por virgula da longitude.</Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
