import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import Stack from '@mui/material/Stack';
import { InputAdornment, FormGroup, FormControlLabel, Checkbox, List, ListItem, ListItemButton, ListItemText, ListItemIcon, Paper, Typography, Grid } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

let arrayCidades = [];

export default function FilterDialog(prop) {
    const { action, fonteDado, open, setOpen } = prop;

    
    const [date, setDate] = React.useState(null);

    const [cidade, setCidade] = React.useState([]);
    const [arrCidade, setArrCidade] = React.useState([]);
    const [buscaCidade, setBuscaCidade] = React.useState();

    React.useEffect(() => {
        
        let cidades = [];
        for (let x in fonteDado) {
            const j = fonteDado[x];
            if (!cidades.includes(j['NOME CIDADE'])) cidades.push(j['NOME CIDADE'])
        }
        arrayCidades = cidades;
        setArrCidade(cidades);

    }, [])

    // const handleChange = (event) => {
    //     setCidade(event.target.value);
    // };
    const handleChangeBuscar = (event) => {
        let arr = arrayCidades.filter((e) => filtrarString(e, event.target.value))
        setArrCidade(arr);
        setBuscaCidade(event.target.value);
    };

    function filtrarString(entrada, val) {
        return entrada.toLowerCase().includes(val.toLowerCase());
    }

    const handleLimpaFiltro = () => {
        if (action) action(null);
        setCidade([]);
        setDate(null);
        // setOpen(false);
    }
    const handleAplicarFiltro = () => {
        const form = {
            cidade: cidade,
            data: date,
            // distancia: document.getElementById("distancia").value,
            // dentro: document.getElementById("dentro").checked,
            // fora: document.getElementById("fora").checked,
            // show: document.getElementById("show").checked
        }
        if (action) action(form);
        setBuscaCidade();
        setArrCidade(arrayCidades);
        setOpen(false)
    }
    const handleClose = () => {
        setOpen(false);
    };
    const handleFiltro = () => {
        console.log(">>handleFiltro<<")
        setOpen(true);
    }

    function SelecionaCidade(value) {
        
        let arraycidade = [...cidade];
        if(cidade.find((e)=> e == value)){
            arraycidade = arraycidade.filter((a)=> a != value);
        }else{
            arraycidade.push(value)
        }
        setCidade(arraycidade);
    }

    return (
        <React.Fragment>
            {/* <div className="topcornerFilter">
                <IconButton aria-label="filter" onClick={handleFiltro} color="secondary">
                    <SearchIcon />
                </IconButton>
            </div> */}
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    FILTRO
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Grid container spacing={2} sx={{ minWidth: 300, minHeight: 200 }}>
                        {/* <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Cidade</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={cidade}
                                    label="Cidade"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={""}>TODAS</MenuItem>
                                    {arrCidade && arrCidade.map((row, index) => (
                                        <MenuItem key={index} value={row}>{row}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid> */}
                        <Grid item xs={12}>
                            <TextField
                                id='buscarCidade'
                                name="buscarCidade"
                                label="Buscar Cidades"
                                // required
                                value={buscaCidade || ''}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                onChange={handleChangeBuscar}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Paper sx={{ height: 300, overflow: 'auto' }}>
                                <List dense component="div" role="list">
                                    {arrCidade && arrCidade.map((row, index) => (
                                        <ListItem key={index} onClick={() => SelecionaCidade(row)}>
                                            <ListItemIcon >
                                                <Checkbox checked={cidade.find((e)=> e == row) ? true : false} />
                                                <ListItemText sx={{ marginTop: '1.2vh'}} primary={row} />
                                            </ListItemIcon>
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleLimpaFiltro}>
                        Limpa Filtro
                    </Button>
                    <Button autoFocus onClick={handleAplicarFiltro}>
                        Aplicar Filtro
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}
