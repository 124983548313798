export function coordenadasListPiaui() {
    return    [
        [-2.571047058969899, -41.68487548828126],
        [-2.6591804023523697, -41.75216674804688],
        [-2.7489830654806044, -41.79336547851563],
        [-2.7613112689761627, -41.82186126708985],
        [-2.7602790168985187, -41.83319091796876],
        [-2.800754111038172, -41.84589385986329],
        [-2.8377872865803977, -41.85379028320313],
        [-2.8724192167674953, -41.865463256835945],
        [-2.9135671349189436, -41.834220886230476],
        [-2.939621708469813, -41.80572509765626],
        [-2.960878257883946, -41.79611206054688],
        [-2.9876227868007104, -41.81190490722657],
        [-3.0212239538480987, -41.830101013183594],
        [-3.05687983963028, -41.86580657958985],
        [-3.0873903134753005, -41.88880920410157],
        [-3.1141293010332802, -41.92417144775391],
        [-3.1418972788251867, -41.92691802978516],
        [-3.160063377992972, -41.941337585449226],
        [-3.1823462543734937, -41.93687438964844],
        [-3.1758256696990044, -41.95301055908204],
        [-3.1936571233423683, -41.97223663330079],
        [-3.225882668493453, -41.988029479980476],
        [-3.246103291549577, -42.007255554199226],
        [-3.2484980550503164, -42.042274475097656],
        [-3.2632403142196686, -42.06699371337891],
        [-3.2632364798710447, -42.10613250732422],
        [-3.27180782435949, -42.1270751953125],
        [-3.288605217532197, -42.12638854980469],
        [-3.2954577557881852, -42.09720611572266],
        [-3.3177405950768875, -42.10235595703126],
        [-3.314306428399967, -42.12501525878906],
        [-3.3297348061392427, -42.12329864501954],
        [-3.3448153895026236, -42.12398529052735],
        [-3.3496110334434017, -42.14561462402344],
        [-3.3760069829291677, -42.14630126953125],
        [-3.3924551510388987, -42.15728759765625],
        [-3.395536159043077, -42.18647003173829],
        [-3.4318724447706246, -42.20191955566406],
        [-3.4294634176867422, -42.22457885742188],
        [-3.4548305996880417, -42.294960021972656],
        [-3.4352849769922718, -42.31967926025391],
        [-3.4442025979324504, -42.34783172607422],
        [-3.452770125260587, -42.36808776855469],
        [-3.446597679735367, -42.387313842773445],
        [-3.470937039162758, -42.39624023437501],
        [-3.4740158326559576, -42.41168975830078],
        [-3.454819098899613, -42.42267608642578],
        [-3.4723060270337442, -42.442588806152344],
        [-3.483956072925316, -42.46559143066406],
        [-3.4719557565913735, -42.487220764160156],
        [-3.4486493413039265, -42.48584747314453],
        [-3.453797242660982, -42.504386901855476],
        [-3.474705852002005, -42.509193420410156],
        [-3.4822415603392143, -42.516403198242195],
        [-3.4914945600099476, -42.53116607666016],
        [-3.512058406079004, -42.53116607666016],
        [-3.5237070664252728, -42.54009246826172],
        [-3.539470992013453, -42.55622863769532],
        [-3.5603748327810547, -42.562408447265625],
        [-3.576821212191526, -42.580604553222656],
        [-3.584014510653957, -42.5991439819336],
        [-3.609032346961768, -42.615623474121094],
        [-3.6292467447379075, -42.623863220214844],
        [-3.6477483580156296, -42.65029907226563],
        [-3.6724187815192497, -42.67227172851563],
        [-3.704283534959349, -42.681198120117195],
        [-3.7234659358573596, -42.67261505126954],
        [-3.747448718771498, -42.67158508300782],
        [-3.7632055166265133, -42.68325805664063],
        [-3.7895867306864703, -42.67227172851563],
        [-3.812195211435967, -42.68909454345703],
        [-3.840286351301782, -42.7035140991211],
        [-3.8704309163839685, -42.71793365478516],
        [-3.902286955065985, -42.72377014160157],
        [-3.928659676562147, -42.74024963378906],
        [-3.94406990225678, -42.76737213134766],
        [-3.9759269646121647, -42.79209136962891],
        [-3.9954458242502082, -42.79380798339844],
        [-3.994755590460872, -42.81578063964844],
        [-4.017365497362004, -42.83363342285157],
        [-4.048532820753788, -42.84599304199219],
        [-4.0793540859288075, -42.85526275634766],
        [-4.106406431668035, -42.87311553955079],
        [-4.12010049446366, -42.901611328125],
        [-4.152636097911836, -42.88684844970704],
        [-4.156395241768964, -42.912254333496094],
        [-4.167011965323925, -42.94075012207031],
        [-4.194066573420285, -42.95997619628907],
        [-4.2238558086727505, -42.98606872558594],
        [-4.26973892735089, -42.98503875732422],
        [-4.311163405005706, -42.97508239746094],
        [-4.338547364979453, -42.963409423828125],
        [-4.376891201010551, -42.96272277832031],
        [-4.386468660837431, -42.95448303222656],
        [-4.383726912997923, -42.930450439453125],
        [-4.428923944252025, -42.87036895751954],
        [-4.459726430523839, -42.87895202636719],
        [-4.477521560184285, -42.851486206054695],
        [-4.512779569070538, -42.85457611083985],
        [-4.53296861508171, -42.87448883056641],
        [-4.563430790192701, -42.86693572998047],
        [-4.593546692909481, -42.88066864013672],
        [-4.625372883288583, -42.898178100585945],
        [-4.649667036947826, -42.91431427001954],
        [-4.678411807904924, -42.951393127441406],
        [-4.6968865521287615, -42.949333190917976],
        [-4.71981270983045, -42.92598724365235],
        [-4.745474837389567, -42.923240661621094],
        [-4.7618952397140895, -42.94555664062501],
        [-4.78824191850825, -42.94864654541016],
        [-4.818006996372501, -42.931480407714844],
        [-4.8385308607025035, -42.901611328125],
        [-4.8758230126313435, -42.895431518554695],
        [-4.915503781382889, -42.8738021850586],
        [-4.9397855981935725, -42.85526275634766],
        [-4.995203076158518, -42.857666015625],
        [-5.038292822874755, -42.84290313720704],
        [-5.077620217870838, -42.83294677734376],
        [-5.110447333940061, -42.816810607910156],
        [-5.1446425742417, -42.807540893554695],
        [-5.187385818455443, -42.79895782470704],
        [-5.224652203524716, -42.8188705444336],
        [-5.2506328517630365, -42.82814025878907],
        [-5.288925674811951, -42.813034057617195],
        [-5.330974132384668, -42.82093048095704],
        [-5.361052003901214, -42.843246459960945],
        [-5.387712566429621, -42.87689208984376],
        [-5.401039386073952, -42.91431427001954],
        [-5.433856219048387, -42.925643920898445],
        [-5.4478641426112695, -42.964439392089844],
        [-5.486147692784524, -42.9730224609375],
        [-5.524422474314534, -42.998771667480476],
        [-5.565087342120306, -43.02383422851563],
        [-5.594470265572078, -43.03516387939454],
        [-5.600956335374476, -43.083915710449226],
        [-5.6265870240363265, -43.09661865234376],
        [-5.65016114763755, -43.079795837402344],
        [-5.695263413185043, -43.08357238769532],
        [-5.731814225974451, -43.075675964355476],
        [-5.7427388556081125, -43.09215545654297],
        [-5.794328640867568, -43.09455871582032],
        [-5.8664003314601505, -43.07464599609376],
        [-5.9036162218851125, -43.09970855712891],
        [-5.98933914628982, -43.051986694335945],
        [-6.044984618282534, -43.07498931884766],
        [-6.106095631245546, -43.04443359375001],
        [-6.1224697447271765, -42.987785339355476],
        [-6.1757295358662185, -42.97096252441407],
        [-6.192786364492706, -42.93491363525391],
        [-6.217021343461812, -42.91259765625],
        [-6.225549780335863, -42.87895202636719],
        [-6.249444097113589, -42.857322692871094],
        [-6.295862315674062, -42.84908294677735],
        [-6.329300572652808, -42.858009338378906],
        [-6.326561314818977, -42.83466339111328],
        [-6.3436276882844025, -42.8298568725586],
        [-6.369903491303649, -42.85457611083985],
        [-6.39378653714797, -42.85835266113281],
        [-6.424494244551759, -42.87792205810547],
        [-6.462705435189866, -42.86933898925781],
        [-6.489310719058222, -42.86659240722656],
        [-6.5046580947063575, -42.88547515869141],
        [-6.513866454913677, -42.8738021850586],
        [-6.535017711921344, -42.886161804199226],
        [-6.576975678077015, -42.8847885131836],
        [-6.6134666895548895, -42.908134460449226],
        [-6.623669250296753, -42.911224365234375],
        [-6.642108275274879, -42.91671752929688],
        [-6.664957217657011, -42.91671752929688],
        [-6.683369854291268, -42.932167053222656],
        [-6.708945274508081, -42.95482635498047],
        [-6.718829276698337, -42.97885894775391],
        [-6.751907777029796, -42.99430847167969],
        [-6.763494066613001, -43.04237365722657],
        [-6.750532034463045, -43.08357238769532],
        [-6.78429623724835, -43.14777374267579],
        [-6.768600946344934, -43.249053955078125],
        [-6.800659617107409, -43.300895690917976],
        [-6.801333360459603, -43.34827423095704],
        [-6.843955712497781, -43.41659545898438],
        [-6.821781385193481, -43.488693237304695],
        [-6.749834715519799, -43.56319427490235],
        [-6.7085868278393495, -43.69159698486329],
        [-6.718147169014475, -43.80970001220704],
        [-6.772710358198638, -43.939476013183594],
        [-6.769285998572223, -44.05139923095704],
        [-6.852488009530236, -44.13345336914063],
        [-6.937357653268103, -44.20246124267579],
        [-7.0068735954928725, -44.26391601562501],
        [-7.102623724590577, -44.29687500000001],
        [-7.119978109548969, -44.31472778320313],
        [-7.186417731470266, -44.50183868408203],
        [-7.227965700018676, -44.540634155273445],
        [-7.308348926555346, -44.614791870117195],
        [-7.33965494626113, -44.66766357421876],
        [-7.395169960806745, -44.688949584960945],
        [-7.3958367226581, -44.714012145996094],
        [-7.360757982808575, -44.74559783935547],
        [-7.379158067159463, -44.77924346923829],
        [-7.361443486802335, -44.81769561767579],
        [-7.428878004980125, -44.881210327148445],
        [-7.48402286915199, -44.98523712158204],
        [-7.514310779134435, -45.15380859375001],
        [-7.568092702777945, -45.22041320800782],
        [-7.613692661074401, -45.3896713256836],
        [-7.713061507131374, -45.46932220458985],
        [-7.808650548705302, -45.508460998535156],
        [-7.895374718620398, -45.539703369140625],
        [-7.993985787284601, -45.56648254394532],
        [-8.080670024901691, -45.574378967285156],
        [-8.163599504875878, -45.58811187744141],
        [-8.243453978873678, -45.644760131835945],
        [-8.338583600070136, -45.69007873535156],
        [-8.389522775888814, -45.70655822753907],
        [-8.390137971387855, -45.71754455566407],
        [-8.42512669893768, -45.73393821716309],
        [-8.442327574090386, -45.7383155822754],
        [-8.476117740381449, -45.74226379394531],
        [-8.49648807721855, -45.74724197387696],
        [-8.50089811196889, -45.738658905029304],
        [-8.527387904092505, -45.75754165649415],
        [-8.551153372503123, -45.76080322265626],
        [-8.582218821601806, -45.79341888427735],
        [-8.609205340776533, -45.76681137084962],
        [-8.617347255459523, -45.7987403869629],
        [-8.65689892781899, -45.815391540527344],
        [-8.699663170732261, -45.833244323730476],
        [-8.716794436634714, -45.841484069824226],
        [-8.740041270683676, -45.889892578125],
        [-8.764133572764118, -45.89332580566407],
        [-8.781775809848414, -45.93727111816407],
        [-8.801455099718444, -45.94362258911133],
        [-8.822320537155992, -45.960102081298835],
        [-8.83877317182904, -45.94499588012695],
        [-8.86625391332718, -45.97314834594727],
        [-8.903568810280483, -45.98052978515626],
        [-8.937145689821115, -45.9939193725586],
        [-8.966480277113996, -45.98070144653321],
        [-8.998527091561632, -45.95941543579102],
        [-9.03260528867192, -45.941905975341804],
        [-9.070240491271635, -45.93006134033204],
        [-9.088373054953406, -45.93074798583985],
        [-9.098710762735568, -45.923194885253906],
        [-9.121256916066448, -45.92731475830079],
        [-9.14803654782001, -45.92164993286133],
        [-9.179898304672463, -45.90070724487305],
        [-9.191077293955079, -45.905170440673835],
        [-9.23310826465025, -45.89847564697266],
        [-9.27241481405493, -45.90105056762695],
        [-9.292231132279689, -45.90482711791993],
        [-9.318490181014147, -45.899162292480476],
        [-9.346270423573944, -45.8785629272461],
        [-9.357614595401232, -45.8485221862793],
        [-9.387259069367047, -45.82019805908204],
        [-9.423502479197271, -45.80234527587891],
        [-9.46261966234037, -45.79187393188477],
        [-9.498005918438439, -45.8016586303711],
        [-9.528478747294342, -45.82242965698243],
        [-9.559288776799884, -45.83616256713868],
        [-9.596698814356635, -45.83667755126953],
        [-9.622591470220458, -45.82019805908204],
        [-9.659826516136325, -45.8316993713379],
        [-9.701285785714827, -45.83238601684571],
        [-9.742061907789896, -45.82242965698243],
        [-9.790616757678881, -45.830497741699226],
        [-9.830364982719885, -45.85144042968751],
        [-9.876877232325233, -45.86191177368165],
        [-9.923381149099065, -45.84800720214844],
        [-9.962944753166179, -45.84749221801758],
        [-10.00233626587436, -45.85916519165039],
        [-10.046288057759766, -45.87581634521485],
        [-10.092937483216222, -45.875473022460945],
        [-10.135015757118499, -45.89384078979493],
        [-10.17708969448183, -45.92473983764649],
        [-10.21138481236644, -45.949630737304695],
        [-10.253450688499113, -45.945854187011726],
        [-10.258339661227428, -45.94602584838868],
        [-10.26323822598691, -46.00147247314454],
        [-10.25242341250378, -46.01142883300782],
        [-10.286048316687427, -46.02190017700196],
        [-10.326753088018789, -45.98138809204102],
        [-10.363059199867024, -45.96216201782227],
        [-10.368116867418108, -45.90911865234376],
        [-10.340076500410422, -45.8866310119629],
        [-10.307480985350642, -45.913925170898445],
        [-10.265590807064786, -45.89538574218751],
        [-10.258505414549695, -45.84663391113282],
        [-10.251917705308696, -45.76423645019531],
        [-10.208661506206395, -45.72801589965821],
        [-10.166423026251085, -45.732994079589844],
        [-10.154772105123357, -45.69385528564454],
        [-10.124857860321914, -45.63858032226563],
        [-10.118104288307794, -45.582618713378906],
        [-10.163911425438561, -45.56699752807618],
        [-10.206318611761523, -45.56356430053711],
        [-10.236555167897553, -45.52888870239258],
        [-10.277774005601515, -45.52305221557618],
        [-10.303611713605367, -45.486488342285156],
        [-10.339248694160954, -45.45404434204102],
        [-10.376230270442177, -45.42709350585938],
        [-10.417597922455718, -45.4255485534668],
        [-10.443895799004691, -45.396537780761726],
        [-10.508423463704219, -45.44563293457031],
        [-10.569853306040944, -45.44116973876954],
        [-10.645109547846063, -45.41610717773438],
        [-10.712245466247108, -45.36186218261719],
        [-10.766211579268735, -45.289421081542976],
        [-10.82354449738184, -45.24787902832032],
        [-10.842080665679388, -45.073814392089844],
        [-10.926384416393445, -44.93476867675782],
        [-10.884885651170851, -44.862327575683594],
        [-10.805973268022742, -44.76173400878907],
        [-10.736159922085367, -44.66114044189453],
        [-10.646753690889462, -44.596595764160156],
        [-10.608299506263117, -44.457206726074226],
        [-10.548558633090686, -44.33773040771485],
        [-10.613725688121832, -44.218254089355476],
        [-10.589742785948166, -44.088134765625],
        [-10.478670596048305, -44.0273666381836],
        [-10.404399937025662, -44.014320373535156],
        [-10.431101598611232, -43.924369812011726],
        [-10.3344871285239, -43.874244689941406],
        [-10.22773635341992, -43.817253112792976],
        [-10.124333070544095, -43.76678466796876],
        [-10.07837989538885, -43.699150085449226],
        [-9.990132326395457, -43.661727905273445],
        [-9.961406580803155, -43.70086669921876],
        [-9.869401217009418, -43.66687774658204],
        [-9.793968964387592, -43.71871948242188],
        [-9.698882617896537, -43.798027038574226],
        [-9.633231245818394, -43.84265899658203],
        [-9.545547433166922, -43.84677886962891],
        [-9.462250419784823, -43.78601074218751],
        [-9.418910689820699, -43.69159698486329],
        [-9.335232645482103, -43.5934066772461],
        [-9.271542181851304, -43.493499755859375],
        [-9.286132254939172, -43.417282104492195],
        [-9.362375341629159, -43.365440368652344],
        [-9.421985797473997, -43.27926635742188],
        [-9.418243698052008, -43.193778991699226],
        [-9.374877648595007, -43.11721801757813],
        [-9.413512864321161, -42.966842651367195],
        [-9.498528092896676, -42.95207977294922],
        [-9.54829182338079, -42.88375854492188],
        [-9.595687200965735, -42.822990417480476],
        [-9.621408045757999, -42.780075073242195],
        [-9.533358726346657, -42.720680236816406],
        [-9.539477532560293, -42.6211166381836],
        [-9.466316945434418, -42.4892807006836],
        [-9.375203960353566, -42.35881805419922],
        [-9.281360487809284, -42.22766876220704],
        [-9.254948446868488, -42.05978393554688],
        [-9.238346788014695, -41.87164306640626],
        [-9.119017881933448, -41.76143646240235],
        [-9.015956284978527, -41.72504425048829],
        [-8.958998206515563, -41.6048812866211],
        [-8.962066017634653, -41.544456481933594],
        [-8.846373093197865, -41.488151550292976],
        [-8.801948596889105, -41.42429351806641],
        [-8.707943391184616, -41.38481140136719],
        [-8.665533755688829, -41.29211425781251],
        [-8.605449933485527, -41.21143341064454],
        [-8.535173040702043, -41.14826202392579],
        [-8.474056867694388, -41.08028411865235],
        [-8.402397233103398, -40.99960327148438],
        [-8.446238043311922, -40.92578887939454],
        [-8.35891721131349, -40.892143249511726],
        [-8.38034437241696, -40.83686828613282],
        [-8.322917717265613, -40.81867218017579],
        [-8.255647345058895, -40.783309936523445],
        [-8.22371734013086, -40.708122253417976],
        [-8.149964676859643, -40.60169219970704],
        [-8.045607581729016, -40.58349609375001],
        [-7.941571692346889, -40.54504394531251],
        [-7.75591632124247, -40.67276000976563],
        [-7.626586321581401, -40.62606811523438],
        [-7.461846984184941, -40.70159912109376],
        [-7.336566574072116, -40.54641723632813],
        [-7.1189434974006325, -40.48770904541016],
        [-7.032729934312565, -40.42694091796876],
        [-6.940378733741148, -40.42556762695313],
        [-6.864376908176557, -40.427970886230476],
        [-6.8050677994584685, -40.37097930908204],
        [-6.813948782665664, -40.42831420898438],
        [-6.7351722545034125, -40.478439331054695],
        [-6.6802817761975595, -40.64804077148438],
        [-6.654714349376509, -40.733871459960945],
        [-6.618904373004321, -40.721855163574226],
        [-6.514177833616504, -40.7918930053711],
        [-6.432652891950312, -40.79566955566407],
        [-6.364760829918358, -40.78742980957032],
        [-6.283202452406391, -40.81008911132813],
        [-6.20402465328203, -40.84751129150391],
        [-6.12722449374388, -40.848197937011726],
        [-6.0545106335966326, -40.87566375732422],
        [-6.0463335734725545, -40.90896606445313],
        [-5.973249428187677, -40.8742904663086],
        [-5.897098063403228, -40.89729309082031],
        [-5.821278684036981, -40.907936096191406],
        [-5.728022685679833, -40.914115905761726],
        [-5.670980647718751, -40.93677520751954],
        [-5.61084765521234, -40.90209960937501],
        [-5.5223390308871805, -40.93505859375001],
        [-5.419123758743735, -40.941581726074226],
        [-5.340514034267483, -40.91926574707032],
        [-5.239659440652816, -40.94123840332032],
        [-5.174364655692632, -40.927848815917976],
        [-5.121025437220452, -41.013336181640625],
        [-5.093334972404587, -41.03496551513672],
        [-5.09163029445384, -41.079940795898445],
        [-5.053319219234169, -41.09916687011719],
        [-5.04682968287426, -41.13075256347656],
        [-4.985938161839652, -41.1379623413086],
        [-4.9938225427507215, -41.148605346679695],
        [-4.937712398326889, -41.19564056396485],
        [-4.938753314800161, -41.22310638427735],
        [-4.867242649366318, -41.250228881835945],
        [-4.822098023619721, -41.241216659545906],
        [-4.805505759923601, -41.232976913452156],
        [-4.786260778844246, -41.21546745300294],
        [-4.771721512399313, -41.21417999267579],
        [-4.766763060922108, -41.238470077514656],
        [-4.75709644745635, -41.25057220458985],
        [-4.745206398233255, -41.2353801727295],
        [-4.7271563593736845, -41.22653961181641],
        [-4.709535268447866, -41.21006011962891],
        [-4.689175524211639, -41.19297981262208],
        [-4.667275670422382, -41.173582077026374],
        [-4.647771517612343, -41.196069717407234],
        [-4.627752763257842, -41.21769905090333],
        [-4.60764800071114, -41.23254776000977],
        [-4.584889769876238, -41.22190475463868],
        [-4.570518393723633, -41.24241828918457],
        [-4.5668421833749075, -41.221733093261726],
        [-4.548785439673049, -41.209030151367195],
        [-4.524569855533039, -41.198558807373054],
        [-4.502665945159115, -41.18765830993653],
        [-4.479648095421789, -41.18250846862794],
        [-4.4537196280348095, -41.17572784423829],
        [-4.430016516611649, -41.16843223571778],
        [-4.405284874792746, -41.15890502929688],
        [-4.3815799036050675, -41.15976333618165],
        [-4.361554911431348, -41.1449146270752],
        [-4.3433261658635915, -41.13444328308106],
        [-4.325438946257446, -41.119937896728516],
        [-4.302157734370335, -41.12053871154786],
        [-4.278449144888808, -41.12525939941407],
        [-4.256880340077673, -41.13066673278809],
        [-4.235139137036215, -41.13590240478516],
        [-4.212027430638967, -41.12019538879395],
        [-4.184291701998877, -41.10002517700196],
        [-4.170427677905515, -41.0910987854004],
        [-4.16555060609334, -41.11693382263184],
        [-4.1448300572827685, -41.12500190734863],
        [-4.124798130969988, -41.14302635192872],
        [-4.103737927256449, -41.13401412963868],
        [-4.079252021980452, -41.122598648071296],
        [-4.0647002221734345, -41.12800598144532],
        [-4.043979572676324, -41.112642288208015],
        [-4.036705592963089, -41.120023727417],
        [-4.0330249566017695, -41.16843223571778],
        [-4.019066406551388, -41.18242263793946],
        [-4.035256192765593, -41.25615119934083],
        [-4.014612459463588, -41.23932838439942],
        [-3.990123725579529, -41.24834060668946],
        [-3.967690822745779, -41.23237609863282],
        [-3.9681248950260453, -41.228513717651374],
        [-3.941145497599238, -41.22113227844238],
        [-3.912202293172147, -41.22954368591309],
        [-3.8982482741843527, -41.2449073791504],
        [-3.8723838844194574, -41.25537872314454],
        [-3.8620260507971453, -41.266193389892585],
        [-3.8376158499145263, -41.27082824707032],
        [-3.8247730260150132, -41.27752304077149],
        [-3.8259756322326486, -41.3005256652832],
        [-3.804473992003741, -41.27949714660645],
        [-3.778608700261781, -41.25675201416016],
        [-3.754971185291694, -41.26104354858399],
        [-3.730047384666173, -41.25743865966797],
        [-3.716517722953416, -41.23812675476075],
        [-3.7045270168272446, -41.25288963317871],
        [-3.691936023420506, -41.28687858581544],
        [-3.6769460745828857, -41.34215354919434],
        [-3.648590672629988, -41.3342571258545],
        [-3.6217801214098317, -41.33271217346192],
        [-3.5930833232612556, -41.343355178833015],
        [-3.571498101379724, -41.3712501525879],
        [-3.557022622531126, -41.3591480255127],
        [-3.5510282355795395, -41.334085464477546],
        [-3.5353487252900493, -41.32558822631837],
        [-3.511873547442478, -41.32507324218751],
        [-3.495512377860329, -41.312112808227546],
        [-3.492260283925729, -41.30352973937989],
        [-3.484548675972376, -41.31958007812501],
        [-3.4543001111032323, -41.32567405700684],
        [-3.4274844283877304, -41.32447242736817],
        [-3.398867562642836, -41.324386596679695],
        [-3.386362233545659, -41.324386596679695],
        [-3.385936972229644, -41.34644508361817],
        [-3.3987924705129005, -41.36249542236329],
        [-3.4093304388000245, -41.38584136962891],
        [-3.388673606855418, -41.38738632202149],
        [-3.388336055585527, -41.39863014221192],
        [-3.363831081271228, -41.39914512634278],
        [-3.3693162812909523, -41.4224910736084],
        [-3.3519616854250054, -41.41493797302247],
        [-3.3416343008518035, -41.41219139099122],
        [-3.330905583934611, -41.40071153640748],
        [-3.3241784041158615, -41.39839410781861],
        [-3.317409154203882, -41.399316787719734],
        [-3.310661296514147, -41.39539003372193],
        [-3.3042990836433335, -41.39590501785279],
        [-3.297829591120656, -41.39223575592042],
        [-3.2911029501324105, -41.38822317123414],
        [-3.2841406225758525, -41.38397455215455],
        [-3.276578319263054, -41.37948989868165],
        [-3.2701089590564383, -41.37547731399537],
        [-3.2622892799964354, -41.37090682983399],
        [-3.2545127484962992, -41.366336345672615],
        [-3.246736145831354, -41.36176586151124],
        [-3.238959483290775, -41.35719537734986],
        [-3.2311827610173816, -41.352624893188484],
        [-3.2218363591194934, -41.34593009948731],
        [-3.1895286226682162, -41.32773399353028],
        [-3.1797648982750655, -41.322412490844734],
        [-3.1600514587551336, -41.32687568664551],
        [-3.141797539642005, -41.31434440612794],
        [-3.1215712345947746, -41.293659210205085],
        [-3.1024595069728984, -41.28499031066895],
        [-3.0881479421081455, -41.255722045898445],
        [-3.0668909130734905, -41.26001358032227],
        [-3.0482070387088243, -41.2602710723877],
        [-3.0356950208653948, -41.25263214111329],
        [-3.01520800474517, -41.25675201416016],
        [-2.9932651674123902, -41.26439094543458],
        [-2.9718366951935447, -41.27048492431641],
        [-2.9641258628692126, -41.309881210327156],
        [-2.933947628990824, -41.321468353271484],
        [-2.9101193712871876, -41.32550239562989],
        [-2.8831164231702098, -41.32704734802246],
        [-2.6899508627148507, -41.33640289306641],
        [-2.6916927950181235, -41.49845123291016],
        [-2.5716249984380357, -41.68453216552735]
    ];
}