import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton } from '@mui/material';
// import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import PinDropIcon from '@mui/icons-material/PinDrop';

import { useFonteDados } from '../services/useFonteDados';
import MapaDialog from './MapaDialog';
import styled from '@emotion/styled';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

function LinkTable() {
    //   const [prova, setProva] = React.useState(null);
    const [page, setPage] = React.useState(0);
    const [total, setTotal] = React.useState(100);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [fonteDado, setFonteDado] = React.useState(null);
    const [fontePaginado, setFontePaginado] = React.useState(null);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [cidade, setCidade] = React.useState(null)
    let [dadoCSV, cidades] = useFonteDados();


    React.useEffect(() => {

        if (!fonteDado) buscaSetting();
    }, [dadoCSV])

    function buscaSetting() {

        if (!cidades) return;
        let arr = []
        for (const [key, value] of cidades) {
            arr.push(value)
        }
        setFonteDado(arr)
    }

    React.useEffect(() => {
        const dado = {
            page: 0,
            rowsPerPage: rowsPerPage,
            filter: null
        }
        buscaLocalProva(dado);
    }, [fonteDado])

    const handleChangePage = (event, newPage) => {

        console.log(">>>handleChangePage<<<", newPage);

        const dado = {
            page: newPage,
            rowsPerPage: rowsPerPage,
            filter: null
        }
        buscaLocalProva(dado);

        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        console.log(">>>handleChangeRowsPerPage<<<", event.target.value);
        setRowsPerPage(parseInt(event.target.value, 10));
        setFontePaginado(fonteDado.slice(0, event.target.value))
        setPage(0);
    };

    function buscaLocalProva(dado) {
        if (!fonteDado) return;
        // Service.POST('/api/localProva/findAll', dado)
        //   .then(r => {
        //     console.log(r);
        //     setTotal(r.size)
        //     setProva(r.dado);
        //   });
        // console.log(dado)
        setFontePaginado(fonteDado.slice(dado.page * dado.rowsPerPage, (dado.page + 1) * dado.rowsPerPage))
        setTotal(fonteDado.length)
    }
    function getTotal(row){
        let qtd = 0;
        for(let x in row.planos){
            qtd++;
        }
        return qtd === 0? '': qtd;
    }
    function getQtd(row, key){
        let qtd = 0;
        for(let x in row.planos){
            const plano = row.planos[x];
            if( plano === key) qtd++
        }
        return qtd === 0? '': qtd;
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: '#8b8b8b',
          color: 'white',
          fontSize: 15,
          fontWeight: 700
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: '#E0E0E0',
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));
    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="simple table" sx={{ marginTop: '40px'}}>
                <TableHead sx={{height: '59px'}}>
                    <TableRow>
                        <StyledTableCell>Cidade</StyledTableCell>
                        <StyledTableCell>30</StyledTableCell>
                        <StyledTableCell>60</StyledTableCell>
                        <StyledTableCell>90</StyledTableCell>
                        <StyledTableCell>100</StyledTableCell>
                        <StyledTableCell>120</StyledTableCell>
                        <StyledTableCell>150</StyledTableCell>
                        <StyledTableCell>180</StyledTableCell>
                        <StyledTableCell>200</StyledTableCell>
                        <StyledTableCell>250</StyledTableCell>
                        <StyledTableCell>270</StyledTableCell>
                        <StyledTableCell>300</StyledTableCell>
                        <StyledTableCell>400</StyledTableCell>
                        <StyledTableCell>420</StyledTableCell>
                        <StyledTableCell>600</StyledTableCell>
                        <StyledTableCell>800</StyledTableCell>
                        <StyledTableCell>1000</StyledTableCell>
                        <StyledTableCell>1024</StyledTableCell>
                        <StyledTableCell>1054</StyledTableCell>
                        <StyledTableCell>1180</StyledTableCell>
                        <StyledTableCell>1300</StyledTableCell>
                        <StyledTableCell>1360</StyledTableCell>
                        <StyledTableCell>Total Cidade</StyledTableCell>
                        <StyledTableCell>Visualizar</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fontePaginado && fontePaginado.map((row, index) => (
                        <StyledTableRow
                            key={index} 
                        >
                            <StyledTableCell>{row["NOME CIDADE"]}</StyledTableCell>
                            <StyledTableCell>{getQtd(row, '30'  ) }</StyledTableCell>
                            <StyledTableCell>{getQtd(row, '60'  ) }</StyledTableCell>
                            <StyledTableCell>{getQtd(row, '90'  ) }</StyledTableCell>
                            <StyledTableCell>{getQtd(row, '100' ) }</StyledTableCell>
                            <StyledTableCell>{getQtd(row, '120' ) }</StyledTableCell>
                            <StyledTableCell>{getQtd(row, '150' ) }</StyledTableCell>
                            <StyledTableCell>{getQtd(row, '180' ) }</StyledTableCell>
                            <StyledTableCell>{getQtd(row, '200' ) }</StyledTableCell>
                            <StyledTableCell>{getQtd(row, '250' ) }</StyledTableCell>
                            <StyledTableCell>{getQtd(row, '270' ) }</StyledTableCell>
                            <StyledTableCell>{getQtd(row, '300' ) }</StyledTableCell>
                            <StyledTableCell>{getQtd(row, '400' ) }</StyledTableCell>
                            <StyledTableCell>{getQtd(row, '420' ) }</StyledTableCell>
                            <StyledTableCell>{getQtd(row, '600' ) }</StyledTableCell>
                            <StyledTableCell>{getQtd(row, '800' ) }</StyledTableCell>
                            <StyledTableCell>{getQtd(row, '1000' ) }</StyledTableCell>
                            <StyledTableCell>{getQtd(row, '1024' ) }</StyledTableCell>
                            <StyledTableCell>{getQtd(row, '1054' ) }</StyledTableCell>
                            <StyledTableCell>{getQtd(row, '1180' ) }</StyledTableCell>
                            <StyledTableCell>{getQtd(row, '1300' ) }</StyledTableCell>
                            <StyledTableCell>{getQtd(row, '1360' ) }</StyledTableCell>
                            <StyledTableCell>{getTotal(row)}</StyledTableCell>
                            <StyledTableCell>
                            <IconButton aria-label="delete"
                                onClick={() => {setCidade( row ); setOpenDialog(true)} }
                            >
                                <PinDropIcon />
                            </IconButton>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>

                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                            colSpan={10}
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'linhas por página',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>

            </Table>
            <MapaDialog open={openDialog} setOpen={setOpenDialog} cidade={cidade}/>
        </TableContainer>
    );
}

export default LinkTable;