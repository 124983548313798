import React from "react";
import './Footer.css'

export default function Footer() {
    return (
        <footer>
            <nav className="site-footer">
                <ul>
                    <div><li className="title-footer">ETIPI</li></div>
                    <li>Av. Pedro Freitas, 1900</li>
                    <li>Centro Administrativo</li>
                    <li>Bairro São Pedro</li>
                    <li>Teresina-PI – CEP: 64.018-900</li>
                </ul>
                <ul className="service-style">
                    <div> <li className="title-footer">Serviços</li></div>
                    <li><a href="http://portal.pi.gov.br/servicos-para-o-cidadao/"> Cidadão</a></li>
                    <li><a href="http://portal.pi.gov.br/servicos-para-o-servidor/">Servidor Público</a></li>
                    <li><a href="http://portal.pi.gov.br/servicos-para-a-empresa/">Gestão Pública</a></li>
                </ul>
                <ul className="service-style">
                    <div> <li className="title-footer">Siga Nossas Redes</li></div>
                    <li><a href="https://www.youtube.com/@ETIPI">YouTube</a> </li>
                    <li><a href="https://instagram.com/etipi.pi?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr">Instagram</a> </li>
                </ul>
                <ul className="service-style"   >
                    <div><li className="title-footer">Quer entrar em Contato com a<br /> ETIPI ou tem alguma dúvida?</li></div>
                    <li><a href="https://portal.pi.gov.br/etipi/fale-conosco/">Fale Conosco</a></li>
                    <li><a href="https://portal.pi.gov.br/etipi/posso-ajudar/">Posso Ajudar</a></li>
                </ul>
            </nav>
            <div id="footer-copy">

                <p className="copyright">Copyright © 2020 ATI. Todos os Direitos Reservados</p>


            </div>
        </footer>
    )
}