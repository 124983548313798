import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { CardActions, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
    MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents, Polygon
} from "react-leaflet";
import L from "leaflet";
import MarkerClusterGroup from 'react-leaflet-cluster'
import roomIconMapa from '../components/Imagens/roomIconMapaVermelho.png';

import { useFonteDados } from '../services/useFonteDados';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
let CustomIcon = L.icon({
    iconUrl: roomIconMapa,
    iconAnchor: [26, 57],
    popupAnchor: [-6, -54]
});

export default function MapaDialog(props) {
    //   const [open, setOpen] = React.useState(false);
    const { open, setOpen, cidade } = props;
    const inputEl = React.useRef(null);
    const [fonteDado, setFonteDado] = React.useState(null);

    let [dadoCSV, cidades] = useFonteDados();

    React.useEffect(() => {
        if( !dadoCSV) return;
        if( !cidade) return;
        let arr = [];
        for(let x in dadoCSV){
            const item = dadoCSV[x]
            if( cidade['NOME CIDADE'] === item['NOME CIDADE']) arr.push(item)
        }
        setFonteDado(arr);
    }, [dadoCSV, cidade]);

    const handleClose = () => {
        setOpen(false);
    };
    const RecenterAutomatically = ({ lat, lng }) => {
        const map = useMap();
        React.useEffect(() => {
            map.setView([lat, lng]);
            map.setZoom(13);
        }, [lat, lng]);
        return null;
    }
    function getLocal(disp) {
        return disp['SECRETARIA'] + " - " + disp['PAG'];
    }
    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth="xl"
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}

            >
                <DialogTitle>{cidade ? cidade['NOME CIDADE'] : ""}</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <CloseIcon />
                </IconButton>
                <DialogContent style={{ height: "90vh" }}>

                    {cidade &&
                        <MapContainer
                            ref={inputEl} style={{
                                position: 'absolute',
                                height: '75vh',
                                width: '98%'
                            }}
                            minZoom={6}
                            center={[-6.5865973, -42.8404006]} 
                            zoom={15} scrollWheelZoom={true}
                            >

                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />

<MarkerClusterGroup
                        chunkedLoading
                    >
                        { fonteDado && fonteDado.map((disp, index) => (
                            // <Marker
                            //     key={index}
                            //     position={[disp.LAT, disp.LNG]}
                            //     title={disp['NOME CIDADE']}
                            //     icon={CustomIcon}
                            // ></Marker>
                            <Marker key={index} position={[disp.LAT, disp.LNG]} icon={CustomIcon}>
                                <Popup>
                                    <b>Cidade:</b> {disp['NOME CIDADE']} <br />
                                    <b>Bairro:</b> {disp.BAIRRO} <br />
                                    <b>Local: </b>{getLocal(disp)}  <br />
                                    <b>Link: </b>{disp['PLANO TOTAL'] + " Mbps"}<br />
                                
                                    <CardActions sx={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "flex-start",
                                        // 👇 Edit padding to further adjust position
                                        p: 0,
                                    }} >
                                    
                                    </CardActions>
                                </Popup>
                            </Marker>
                        ))}
                    </MarkerClusterGroup>

                            <RecenterAutomatically lat={cidade.LAT} lng={cidade.LNG} />
                            
                        </MapContainer>
                    }
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}