import React, { useEffect } from "react";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Stack, Button, Container } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Service from "../services/Service";
import ArquivoService from '../services/ArquivoService';
import PontoReferenciaMapa from "./PontoReferenciaMapa";

function Settings() {
    const navigate = useNavigate();

    const [wait, setWait] = React.useState(false);
    const [selectedFileUpload, setSelectedFileUpload] = React.useState();
    // const [anexo, setAnexo] = React.useState();
    const [mensagem, setMensagem] = React.useState();

   
    const atualizaDevice = (anexo) => {
        if (anexo ) {
            const dado = {      
                file: anexo,
            }
            // setOpen(true)
            Service.POST('/api/device/atualiza', dado)
                .then(r => {
                    // setOpen(false)
                    navigate("/home")
                });

        } 
    }
    const upload = () => {
        let file = document.getElementById("file");
        let dados = new FormData();
        setWait(true);
        dados.append('file', file.files[0]);
        ArquivoService.upload(dados).then((result) => {
            setWait(false);
            var nm = file.value.split(/(\\|\/)/g).pop();

            let anexo = {
                nome: nm,
                uuid: result.uuid
            };
            file.value = null;
            atualizaDevice(anexo);
        }).catch((e) => {
            setWait(false);
            setMensagem({ tipo: 'error', mensagem: e.message });
        });
    }
    const addAnexo = () => {
        let file = document.getElementById("file");
        file.click();
    }

    return (
        <Container style={{marginTop:10}}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={wait}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Stack spacing={1}
                // direction="row"
            >
                <div>
                <Button onClick={addAnexo}
                    color="secondary" variant="contained" endIcon={<DriveFolderUploadIcon />}>
                    Enviar CSV para Upload
                </Button>
                </div>
                <div>
                    <PontoReferenciaMapa/>
                </div>
                
            </Stack>
            <input type="file" id="file" style={{ display: 'none' }} onChange={upload} accept="application/csv" />

        </Container>
    )
}

export default Settings;