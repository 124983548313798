
import * as React from "react";
import {
  Route,
  Link,
  BrowserRouter,
  Routes,
} from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
// import './App.css';
import Mapa from './components/Mapa';
import MainAppBar from "./components/MainAppBar";
import Settings from "./components/Setings";
import SignIn from "./components/SignIn";
import LinkTable from "./components/LinkTable";
import MapaConsolidado from "./components/MapaConsolidado";

import { datadogRum } from '@datadog/browser-rum';
 
datadogRum.init({
    applicationId: '8bbaf91e-e613-406c-8ba5-483044267a95',
    clientToken: 'pub73d461c7cc9602d0911930cb17202f9a',
    site: 'us5.datadoghq.com',
    service: 'poc-1',
    env: 'poc',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0', 
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});

function App({ children }) {
  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Routes>
          <Route path="/" element={<div style={{backgroundColor: 'gold'}}><MainAppBar /></div>}>
            <Route path="" element={<div><Mapa /></div>} />
            <Route path="home" element={<div><Mapa /></div>} />
            <Route path="table" element={<LinkTable />} />
            <Route path="consolidado" element={<MapaConsolidado />} />
            <Route path="settings" element={<Settings />} />

            <Route path="*" element={<NoMatch />} />
          </Route>
          <Route path="signIn" element={<SignIn />} />
        </Routes>
      </LocalizationProvider>
    </BrowserRouter>
  );
}

export default App;


function NoMatch() {
  return (
    <div>
      <h2>Pagina não encontrada</h2>
      <p>
        <Link to="/">volte para a página inicial</Link>
      </p>
    </div>
  );
}